
export const serverPath = {
    init: '/init/',
    main: '/',
    process: '/process/',
    projects: '/projects/',
    project: '/project/',
    share: '/share/',
    saveSurveyData: '/save-survey-data/',
    wondercheck: '/wondercheck/',
    staticISample3: '/static/i/sample.pptx?3',
    queuePing: '/queue-ping/',
    removeVisual: '/remove-visual/',
    setColor: '/set-color/',
    remake: '/remake/',
    download: '/download/',
    downloadPing: '/download-ping/',
    upload: '/upload/',
    uploadLogo: '/upload-logo/',
    deleteLogo: '/logo-delete/',
    searchMedia: '/search-media/',
    stripe: {
        stripe_subscription_cancel: '/stripe/subscription/cancel/',
    },
    team: {
        teamRequest: '/team/request/',
    },
    user: {
        logout: '/logout/',
        login: '/login/',
        passwordReset: '/password-reset/',
        register: '/register/',
        password: '/password/',
        updateOptions: '/update-options/',
        sendEmailConfirm: '/send-email-confirm/',
        purchase: '/purchase/',
    },
    draft: {
        draftCreate: '/create-draft/',
        draftUpdateParams: '/draft/update-params/',
        draftProcess: '/draft/process/',
        draftRemoveVisual: '/draft/remove-visual/',
        draftCreateV2: '/draft/create/',
        draftRemoveSlide: '/draft/remove-slide/',
        draftCloneSlide: '/draft/clone-slide/',
    },
    slideShooting: {
        slideShootingVersion: '/slide-shooting/versions/',
        slideShootingTheme: '/slide-shooting/themes/',
        slideShootingSetOptions: '/slide-shooting/set-option/',
        slideShootingProcess: '/slide-shooting/process/',
        slideShootingQueuePing: '/slide-shooting/queue-ping/',
        slideShootingFiles: '/slide-shooting/files/',
    },
    slide: {
        addSlide: '/add-slide/',
        cloneSlide: '/clone-slide/',
        remakeSlide: '/remake-slide/',
        processSlide: '/process-slide/',
        removeSlide: '/remove-slide/',
        regenSlide: '/regen-slide/',
    },
    logo: {
        logoDelete: '/logo-delete/',
        logoUpload: '/upload-logo/',
    },
    preset: {
        deletePreset: '/delete-preset/',
    },
}