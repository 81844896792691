import {createApi, EndpointBuilder} from "@reduxjs/toolkit/query/react";
import {appBaseQuery} from "./base-query";
import {serverPath} from "../../utils/server-path";


const presetApi = createApi({
    reducerPath: 'presetApi',
    baseQuery: appBaseQuery(),
    tagTypes: ['PRESET'],
    endpoints: (build) => ({
        deletePreset: build.query({
            query: ({ presetId}: { presetId: string }) => ({
                url: `${serverPath.preset.deletePreset}${presetId}`,
            }),
        }),
        setColor: build.mutation({
            query: (body: { color: string, "text-color":Array<string>}) => ({
                url: `${serverPath.setColor}`,
                method: 'POST',
                body,
            })
        })
    }),
});

export const {
    useLazyDeletePresetQuery,
    useDeletePresetQuery,
    useSetColorMutation,
} = presetApi;

export default presetApi;