export const ONBOARDING_TYPE = 'presentation';
export const ONBOARDING_MESSAGE = {
    description: 'Press the button to get a design tailored to the slide\'s content using AI',
    isFullWidthChild: true,
    onBoardType: ONBOARDING_TYPE,
    isScrollIntoView: true,
    position: 'vertical',
    alignmentX: 41,
    alignment: 181,
    xPosition: 'center',
    yPosition: 'bottom',
    customWidth: 280,
}
export const ONBOARDING_MESSAGE_RESULT = {
    title: 'Get the updated slide design',
    description: 'Click the button to obtain the slide design reflecting the changes made, tailored just for you.',
    isFullWidthChild: true,
    position: 'vertical',
    alignmentX: 41,
    alignment: 181,
    xPosition: 'center',
    yPosition: 'bottom',
    customWidth: 280,
    onBoardType: ONBOARDING_TYPE,
}
export const ONBOARDING_MESSAGE_CHANGE_COLORS = {
    title: 'Change slide design easily',
    description: 'Customize slides with ease using Slide styling panel. Click “Change colors” to shuffle colors.',
    alignment: 256,
    isFullWidthChild: true,
    onBoardType: 'presentation',
    isScrollIntoView: true,
}
export const ONBOARDING_MESSAGE_CHANGE_EDIT = {
    title: 'Adjust design elements directly here',
    description: 'Edit text, icons and images by clicking the pencil icon. Customize any text, images or icons on the slide.',
    alignment: 199,
    onBoardType: ONBOARDING_TYPE,
}
export const ONBOARD_MESSAGE_EDIT_CONTENT = {
    alignment: 84,
    title: "Modify the structure and content",
    description: "Use the button to revisit the slide content for editing and adding visual elements.",
    nowrapDisabled: true,
    onBoardType: ONBOARDING_TYPE,
    customWidth: 280,
    isScrollIntoView: true,
}
export const ONBOARDING_MESSAGE_STYLE = {
    title: 'Set up the design options',
    description: 'Choose the color, shape, and font that perfectly match your presentation style.',
    alignment: 148,
    xPosition: "right",
    yPosition: "bottom",
    onBoardType: ONBOARDING_TYPE,
}
export const ONBOARDING_MESSAGE_SAVE = {
    description: 'Click to start generating!',
    xPosition: "right",
    alignment: 48,
    yPosition: "top",
    isFullWidthChild: true,
    onBoardType: ONBOARDING_TYPE,
    nowrapDisabled: true,
}
export const ONBOARDING_MESSAGE_FINISH = {
    title: 'Download the fully editable PPTX',
    description: 'Download the file and feel free to make any necessary adjustments.',
    alignment: 135,
    xPosition: "right",
    yPosition: "bottom",
    onBoardType: ONBOARDING_TYPE,
    customWidth: 280,
}
export const ONBOARDING_MESSAGE_EDIT = {
    alignment: 207,
    childrenStyle: {display: 'flex', gap: '20px', width: '100%'},
    isFullWidthChild: true,
    title: "Apply or cancel edits",
    onBoardType: ONBOARDING_TYPE,
    description: "To save your content edits, click “Save”. To cancel changes and return to slide editing, click “Close”.",
    position: "vertical",
    xPosition: "edge",
    yPosition: 'bottom',
}