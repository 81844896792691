import React, {useEffect, useState} from 'react';
import { Outlet, useOutletContext} from "react-router";
import PropTypes from "prop-types";
import {useLocation, useNavigate} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import Header from "../../../common/header";
import Footer from "../../../common/footer";
import { TrialPopup } from "../../template";
import {AccountConfirmNotify, ResendConfirmAccountNotify} from "../../../components/template";
import {route_path} from "../../../utils/route_path";

const DefaultLayout = (props) => {
    const { updateData, context } = props;
    const [ trialPopup, setTrialPopup ] = useState({ type: null, callback: null, onClose: null});
    const [ resendNotify, setResendNotify] = useState(false);
    const [ sendConfirmNotify, setSendConfirmNotify ] = useState(false)
    const prevOutletContext = useOutletContext();
    const location = useLocation();
    const navigate = useNavigate();
    const withoutFooterLocations = ['project', 'create'];

    useEffect(() => {
        if (window.location.search.includes('email-confirmed=True')) {
            //window.history.replaceState({}, document.title, location.href.replace(/(\?|&)+email-confirmed=True/, ''));
            setSendConfirmNotify(true);
            if (window.localStorage.getItem('genState')) {
                let savedState = JSON.parse(window.localStorage.getItem('genState'));
                window.localStorage.removeItem('genState');
                window.location.href = `${route_path.project}${savedState.projectId}/`;
            }
        }
    }, []);

    function clearConfirmNotifyAndClose(){
        setSendConfirmNotify(false);
        navigate(location.pathname);
    }

    function closeTrialPopupHandler(close){
        trialPopup.onClose?.();
        setTrialPopup({ type: null, callback: null, onClose: null });
    }
    function downloadTrialHandler(callBack) {
        callBack();
    }
    return (
        <>
            <Header context={context} updateData={updateData} />
                <main className="min-h-[calc(100vh-100px)]">
                    <Outlet context={{context, updateData, setTrialPopup, setResendNotify, setSendConfirmNotify, ...prevOutletContext}}/>
                </main>
            <AnimatePresence>
                { trialPopup.type &&
                    (<motion.div
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1, transition: { easy: 'easyIn' } }}
                        exit={{ opacity: 0, scale: 0 }}
                    >
                        <TrialPopup
                            type={trialPopup.type}
                            onClickDownload={() => downloadTrialHandler(trialPopup.callBack)}
                            context={context}
                            close={closeTrialPopupHandler} />
                    </motion.div>)}
                { resendNotify &&
                    (<motion.div
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1, transition: { easy: 'easyIn' } }}
                        exit={{ opacity: 0, scale: 0 }}>
                        <ResendConfirmAccountNotify close={() => { setResendNotify(false) }}/>
                    </motion.div>)}
                {
                    sendConfirmNotify &&  (<motion.div
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1, transition: { easy: 'easyIn' } }}
                        exit={{ opacity: 0, scale: 0 }}>
                        <AccountConfirmNotify close={clearConfirmNotifyAndClose} /></motion.div>
                    )
                }
                </AnimatePresence>
                {!withoutFooterLocations.includes(location.pathname.split('/')[1]) ? <Footer/> : <></>}
                    </>
                    );
                };

                DefaultLayout.prototype = {
                updateData: PropTypes.func,
                context: PropTypes.object,
}

export default DefaultLayout;