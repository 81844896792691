import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const Button = (props) => {
    const {
        isLink = false,
        size="small",
        rounded="small",
        label,
        onClick,
        variant="primary",
        isFullWidth=false,
        isDisabled = false,
        link="/" } = props;

    function onClickButtonHandler (event) {
        event.preventDefault();
        onClick?.();
    }
    const sizeList = {
        middle: 'ui_button__size__middle',
        extra_middle: 'ui_button__size__extra_middle',
        large: 'ui_button__size__large',
        extra_small: 'ui_button__size__extra_small',
        small: 'ui_button__size__small',
    }
    const variantList = {
        primary: 'ui_button__variant__primary',
        outline: 'ui_button__variant__outline'
    }

    const roundedList = {
        small: 'ui_button__rounded__small',
        large: 'ui_button__rounded__large',
        middle: 'ui_button__rounded__middle'
    }

    const generateButtonClassName = ` ${ sizeList[size]} ${variantList[variant]} ${roundedList[rounded]} ${isFullWidth ? 'ui_button__full_width' : ''}`;

    if(isLink){
        return <div><Link to={link} className={"ui_button" + generateButtonClassName}>{label}</Link></div>
    }
    return (
        <div className={isFullWidth ? 'ui_button__full_width': ''}>
            <button
                onClick={onClickButtonHandler}
                className={`ui_button ${generateButtonClassName} ${isDisabled ? 'ui_button__disabled'  : ""}`}
                disabled={isDisabled}
            >
                {label}
            </button>
        </div>
    );
};

Button.prototype = {
    variant: PropTypes.oneOf(['primary', 'outline']),
    isLink: PropTypes.bool,
    size: PropTypes.oneOf(['middle', 'small', 'extra_middle', 'extra_small', 'large']),
    rounded: PropTypes.oneOf(['small', 'large', 'middle']),
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    link: PropTypes.string,
    isFullWidth: PropTypes.bool,
    isDisabled: PropTypes.bool,
}

export default Button;