import React from 'react';
import *  as projectIcons from './project-icons';
import PropTypes from "prop-types";

const Icon = (props) => {
    const { className, name, size, fill="#FFFFFF" } = props;
    const Elem = projectIcons[name];
    if(!name || !Elem) return <></>
    return (
        <div className={className}>
            <Elem size={size} />
        </div>
    );
};

Icon.prototype = {
    size: PropTypes.number,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    fill: PropTypes.string,
}

export default Icon;