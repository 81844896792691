import React, { useState } from 'react';
import { cookie } from '../../lib/utils';

function CorporateModal({ close }) {
	const [success, setSuccess] = useState(false);
	
	async function submitForm(e) {
		e.preventDefault();
		let body = new FormData(e.target);

		let response = await fetch('/team/request/', {
			method: 'POST',
			body: body,
			headers: {
				'X-Requested-With': 'XMLHttpRequest', 
				'X-CSRFToken': cookie.get('csrftoken')
			}
		});

		response = await(response.json());

		if (response.status) {
			setSuccess(true);
		}
	}

	return(
		<div className="about_modal about_modal-corporate show">
			<div className="about_modal_overlay" onClick={close}></div>
			<div className={'about_modal_window' + (success ? ' success' : '')}>
				<div className="about_modal_close" onClick={close}>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5893 0.458496L7.93196 6.1145L2.27596 0.458496L0.390625 2.34383L6.04662 7.99983L0.390625 13.6558L2.27596 15.5412L7.93196 9.88516L13.5893 15.5412L15.4746 13.6558L9.81863 7.99983L15.4746 2.34383L13.5893 0.458496Z" fill="#6E718F"/></svg>
				</div>
				<div className="about_modal_title  ff-raleway">Order a demo call</div>
				<div className="about_modal_descr">Describe the needs of your company and leave a phone or email for contact</div>
				<form className="about_modal_form" onSubmit={(e) => {submitForm(e)}}>
					<input className="about_modal_input ff-raleway" type="text" required name="name" placeholder="Your name" />
					<input className="about_modal_input ff-raleway" type="text" required name="company" placeholder="Your company" />
					<input className="about_modal_input ff-raleway" type="email" required name="email" placeholder="Work email" />
					<button className="about_modal_submit main_btn main_btn-l" type="submit">Send</button>
				</form>
				<div className="about_modal_success">Thanks! We will contact you soon and tell you everything</div>
			</div>
		</div>
	);
}

export default CorporateModal;