import { configureStore } from '@reduxjs/toolkit';

import commonSlice from "./slice/common.slice";
//import slideSlice from "./slice/slide.slice";
import presentationSlice from "./slice/presentation.slice";

import { slideApi } from "./api/slide.api";
import presentationApi from "./api/presentation.api";
import presetApi from "./api/preset.api";

export const store = configureStore({
    reducer: {
        [slideApi.reducerPath]: slideApi.reducer,
        [presentationApi.reducerPath]: presentationApi.reducer,
        [presetApi.reducerPath]: presetApi.reducer,
        //slideState: slideSlice,
        commonState: commonSlice,
        presentationState: presentationSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(
        slideApi.middleware,
        presentationApi.middleware,
        presetApi.middleware,
        )
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;