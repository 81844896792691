import {ThunkDispatch, UnknownAction} from "@reduxjs/toolkit";
import {PromiseWithKnownReason} from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import {BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta} from "@reduxjs/toolkit/dist/query/react";
import {QueryFulfilledRejectionReason} from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import { setGlobalRequest } from "./slice/common.slice";

const globalRequestBlocker = ({ dispatch, queryFulfilled }: { dispatch:  ThunkDispatch<any, any, UnknownAction>,
    queryFulfilled: PromiseWithKnownReason<{     data: any;     meta: {} | undefined; }, QueryFulfilledRejectionReason<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>>> }) => {
    dispatch(setGlobalRequest({ requesting: true}));
}

export default globalRequestBlocker;