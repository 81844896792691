import React from 'react';
import LocalizedStrings from 'react-localization';

let t = new LocalizedStrings({
	en: {
		Login: 'Log in',
		Signup: 'Sign up',
		Logout: 'Log out',
		Loading: 'Loading',
		loadingDone: 'Loading completed',
		ShapeAndColor: 'Shape and color',
		Style: 'Style',
		Color: 'Color',
		Theme: 'Theme',
		Shape: 'Shape',
		Font: 'Font',
		Logo: 'Logo',
		Result: 'Result',
		color: 'color',
		font: 'font',
		logo: 'logo',
		weSupportOnlyPPTX: 'We support only pptx, please select file in this format',
		weSupportOnlyJpgPng: 'We support only jpg and png, please select file in this format',
		oopsSomethingBroke: 'Oops, something went wrong',
		DontWorryWeSaved: 'Don`t worry, we have saved the latest edits. Please refresh this page to access your slides',
		Refresh: 'Refresh',
		pleaseReloadPageAndTryAgain: 'Please reload the page and try again',
		internetProblem: 'Internet problem, please wait',
		maximumSizeExceeded: 'Maximum file size is exceeded',
		FileSizeShouldNotExceed: 'File size should not exceed {0} Mb',
		admin: 'admin',
		Owner: 'Owner',
		Balance: 'Balance',
		Refill: 'Refill',
		Please: 'Please',
		ToStartTrialYouNeedTo:'To start the trial, you need to',
		confirmYourEmail: 'confirm your email',
		Payments: 'Payments',
		Settings: 'Settings',
		settings: 'settings',
		TrialExpiredPlease: 'Trial expired.{0}To keep using Wonderslide, please',
		SubscriptionExpiredPlease: 'Subscription expired.{0}To keep using Wonderslide, please',
		noSubscription: 'No subscription',
		daysLeft: 'days left',
		trialExpiresInDays: 'Trial expires in: {0} days',
		Less1DayTrial: 'Trial expires in less than 1 day',
		upgradePlan: 'Upgrade plan',
		upgradeYourPlan: 'upgrade your plan',
		upgradeThePlanPlease: 'Upgrade the plan, please',
		myProfile: 'My profile',
		mySlides: 'My slides',
		Slides: 'Slides',
		Users: 'Users',
		corporateStyle: 'Corporate style',
		addYourPresentation: 'Upload draft of your presentation',
		uploadDraftOfYourPresentation: 'Upload a pptx draft of your presentation',
		upTo10Slides: 'Service creates a design for the first {1} slides for non-registered users.{0}Expand limits up to {2} slides - complete registration. It’s free! 🤩{0}Split the presentation into parts if you have more slides.',
		selectPptxFileOrDrag: 'Select .pptx file or drag-and-drop here, or take our ',
		ChooseDraft30Mb: 'Choose a {0}, up to 30 MB, up to {1}.',
		draft: 'draft',
		_30slides: '30 slides',
		exampleFile: 'example file',
		uploadInfoText: 'Use this file to see how Wonderslide works, if you don’t have your own',
		chooseFile: 'Select file',
		downloadExample: 'Download .pptx presentation example',
		demoPopupTitle: 'Congratulations! You have a free 7-day trial',
		demoPopupText1: 'You have full unlimited access to Wonderslide',
		demoPopupText2: 'Before you start, try our demo file to see how it works:',
		tryWithDemoFile: 'Try with a demo file',
		skipDemo: 'Skip demo',
		Cover: 'Cover',
		Title: 'Title',
		ThisIsHowTextLooks: 'This is how the text{0}in the presentation{0}looks like',
		Welcome: 'Welcome!',
		Limitations: 'Limitations',
		limitationsText: 'For non-registered users, Wonderslide creates a{0}design for the first 5 slides. Expand limits up to 30 slides - complete registration. It’s free!',
		advicesForPresntation: 'Advices for a draft presentation',
		RequestLiveDemo: 'Request a live demo',
		liveDemoBannerText: 'Have any questions or doubts{0}about Wonderslide? We’ll gladly{0}show you the ropes, free of charge.',
		Request: 'Request',
		GetIt: 'Get it',
		LimitedOffer: 'Limited offer',
		LimitedOfferBannerText: 'Tell us about your experience{0}and get 3 months of Wonderslide Premium!',
		TipsAndTricks: 'Tips & tricks',
		TipsAndTricks1: 'Use black text on a white background.',
		TipsAndTricks2: 'Write a list of up to 6 items equal in length.',
		TipsAndTricks3: 'Make two lists or slides if you have a long list.',
		TipsAndTricks4: 'Place no more than 3 photos on a slide.',
		advicesFirst: 'Upload the simple version: black text on a white background with images. It will help the service offer the best design.',
		Text: 'Text',
		List: 'List',
		Images: 'Images',
		ChartsGraphsTables: 'Charts, graphs, tables',
		advicesText1: 'Use up to 50% of slide area for the text.',
		advicesText2: 'Decline brutal ways to place text, such as a table with transparent borders.',
		advicesText3: 'If you use empty gaps for text formatting, the design will not show them correctly.',
		advicesList1: 'Use a list of up to 6 items. If there are more, make two lists or two slides.',
		advicesList2: 'Formulate points of equal length.',
		advicesList3: 'The service aligns all items perfectly.',
		advicesImages1: 'Place one or a few pictures, but not too many. It can be a photo, a screenshot of a table, or a{0}graph.',
		advicesImages2: 'Avoid background images.',
		advicesChartsGraphsTables: 'The service is just learning how to work with these objects. You can replace tables and graphs when editing in PowerPoint.',
		selectColor: 'Select color',
		corporateColors: 'Corporate colors',
		colorHelperText: 'The service defines areas to be filled with color. Use the color of your brand or pick one that best suits your presentation.',
		styleHelperText: 'The service tailors slide composition and aesthetics to the chosen style. Select a style that aligns with your presentation\'s tone to boost viewer engagement.',
		themedImages: 'Themed images',
		themedImagesHelperText: 'The service contains high-quality images and icons. It will insert suitable assets into your presentation.',
		ChooseYourBrandColor: 'Choose your brand color',
		SaveNewColor: 'Save new color',
		colorExists: 'Color already exists',
		chooseShapeMsg: 'Choose a shape that’s in your brand{0}identity or resembles your logo',
		selectStylisticShape: 'Select stylistic shape',
		shapeHelperText1: 'Use a shape that’s in your brand identity or resembles your logo as a{0}corporate style element.',
		shapeHelperText2: 'The service places inside the shape:',
		shapeHelperTextItem2: 'key numbers;',
		shapeHelperTextItem3: 'list elements;',
		shapeHelperTextItem4: 'important points;',
		shapeHelperTextItem1: 'slide background;',
		chooseFont: 'Select font',
		FontHelperText: 'Select a font to make your presentation stand out.',
		fontComfortaaDescr: 'gentle, soft',
		fontMontserratDescr: 'solid, modern',
		fontOpenSansDescr: 'universal, neutral',
		fontOswaldDescr: 'official, scientific',
		fontNunitoDescr: 'friendly, neutral',
		fontExo_2Descr: 'futuristic, technological',
		fontIbm_plex_sansDescr: 'corporate, premium',
		fontAlumniSansDescr: 'digital, natural',
		fontPlayDescr: 'reserved, financial',
		chooseLogo: 'Want to add a logo?',
		logoText1: 'The logo is displayed on every slide of your presentation.',
		logoText2: 'We collected high-quality logos of major companies. Search for your logo on our list. It can be there. All the information is taken from open sources. Customers of corporate accounts can upload their own logo',
		currentSettings: 'Current settings',
		changeAndGenerateAgain: 'Change and generate again',
		genResultPanelText1: 'You’re all set! Download your presentation{0}to finish it in PowerPoint',
		genResultPanelText2: 'Artificial intelligence designed your presentation{0}and arranged the elements in order. Select another{0}layout if you don’t like the design.',
		toSeePresentationUpgrade: 'To see the full presentation, please upgrage your subscription plan',
		Done: 'Done',
		Next: 'Next',
		waitForUploading: 'Wait for uploading',
		resultText1: 'Tip: Do not overload your slide for better understanding',
		resultText2: 'Now you can download and finish it with your own Power Point',
		resultText3: 'Use the rule: "One slide — one thought". Divide a large amount of information into several slides. Remove the excess.',
		resultText4: 'Artificial intelligent designed your presentation and arranged the elements in order. Select another layout if you don’t like the design. ',
		downloadPresentation: 'Download presentation',
		Download: 'Download',
		Generate: 'Generate',
		waitForGenerating: 'Wait for generating',
		preparingForDownload: 'Preparing for download',
		ShareAllSlides: 'Share all slides',
		willDebit: 'Debit',
		slide: 'slide',
		slides: 'slides',
		slides2: 'slides',
		Choose: 'Select',
		AutomaticSelection: 'Automatic selection',
		NoLogo: 'No logo',
		UploadLogo: 'Upload logo',
		UploadLogoText: 'Select your .png, .jpg file or drag-and-drop here',
		useBlackBg: 'Use black background',
		SelectYourFile: 'Select your file',
		Corporative: 'Corporate',
		refillTitle: 'One more step to wonder-slides!',
		refillText: 'If you want to download a presentation, please choose{0}one of the appropriate plans',
		corporateSubscriptionEnded: 'Corporate subscription ended',
		refillTextCorporate: 'Please get in touch with the administrator of your corporate{0}account to renew your subscription',
		refillAccount: 'Account refill',
		Refill: 'Refill',
		settingUpFonts: 'Fonts setting',
		selectingObjects: 'Object selecting',
		repaintingFigures: 'Shapes recoloring',
		tryAgain: 'Try again',
		redesignSlide: 'Redesign slide',
		previousDesign: 'Previous design',
		changeColors: 'Change colors',
		ReplaceImages: 'Replace images',
		ChangeLayout: 'Change layout',
		ChangeBgPhoto: 'Change background image',
		returnPrevious: 'Return the previous one',
		MakeWhiteBackground: 'Make background white',
		presentationIsProcessing: 'Presentation in process',
		presentationIsReady: 'Presentation is done',
		processingIsDone: 'Processing is completed',
		maxSlidesNotification: 'We have processed only {0} slides, there are +{0} of them in your presentation. To process a large presentation, break it into parts and upload it several times.',
		maxTrialSlidesNotification: 'In the trial version, the service only processes the first{2}{0} slides. Please {1} to increase the limit{2}up to 30 slides.',
		selectSubscription: 'select a subscription',
		Search: 'Search',
		toStartCreatingChooseColor: 'To start creating a presentation choose your brand color first',
		letsStart: 'Let’s start!',
		debugInformation: 'Debug information',
		tryToUploadPopupText: 'Try it with your presentation now',
		slideErrorText: 'Slide creation error. Please try dividing content into a{0}few slides. If the problem persists, email us at ',
		resultLoaderTitle1: 'Analyzing slide',
		resultLoaderTitle2: 'Processing text',
		resultLoaderTitle3: 'Adjusting composition',
		resultLoaderTitle4: 'Applying icons',
		resultLoaderTitle5: 'Selecting pictures',
		resultLoaderTitle6: 'Recoloring',
		resultLoaderTitle7: 'Beautifying',
		resultLoaderText1: 'Prepare the presentation draft - add text, pictures, slides and graphs',
		resultLoaderText2: 'Avoid adding design elements, such as icons or background pictures',
		resultLoaderText3: 'Try limiting each slide to one idea to avoid clutter',
		resultLoaderText4: 'Bullet points are your friend',
		resultLoaderText5: 'Prioritize simple lists with short entries',
		resultLoaderText6: 'Avoid using subparagraphs',
		resultLoaderText7: 'No more than 2 graphs/tables per slide',
		chatgptInfo: 'The highlighted text has been edited, replaced or rewritten by ChatGPT',
		chatgptInfo2: 'This text has been edited by ChatGPT',
		textCutOffInfo: 'This text has been cut off',
		Copy: 'Copy',
		Icon: 'Icon',
		ImagesCuratedByWonderslide: 'Images curated by Wonderslide',
		SelectNewImage: 'Select new image',
		ChangingBackgroundPhoto: 'Changing background photo',
		ShowMeChanges: 'Show edits',
		toDisableGptGoTo: 'To disable the experimental use of ChatGPT, go to ',
		Editing: 'Editing',
		Number: 'Number',
		Unit: 'Unit',
		thisTextHasBeenEditedOrTruncated: 'The text was shortened or rewritten because it was too long.',
		OriginalText: 'Original text',
		ico: 'ico',
		SelectNewIco: 'Select new ico',

		dataStorage: 'Data storage',
		dataStorageDescr: 'We make great efforts to ensure the security of your data. We never share your presentations with third parties. Our team can analyze your data only to improve the service.',
		dataStorageCheckboxLabel: 'Check this option if you want your presentations to be deleted immediately after processing and not available even for our team. To delete the presentations you uploaded to the service earlier, please email us at support@wonderslide.com.',
		EnableExperimentalFeatures: 'Enable experimental features',
		experimentalFeaturesCheckboxLabel: 'Allow to use of external AI systems, such as Large Language Models, to edit text content (may accidently lead to distortion of ideas or facts).',
		changesSaved: 'Changes saved',
		cancelSubscription: 'Cancel subscription',
		upgradeSubscription: 'Upgrade subscription',
		Ok: 'Ok',
		OK: 'OK',
		trialWillCloseOn: 'The trial will close on',
		paidUntil: 'paid until',
		doYouWantToCancelSubscription: 'Do you want to cancel your subscription?',
		ifYouCancelSubscription: 'If you cancel your subscription, the service will continue to work{0}until ',
		Yes: 'Yes',
		No: 'No',
		Upgrade: 'Upgrade',

		changePassword: 'Change password',
		currentPassword: 'Current password',
		oldPassword: 'Old password',
		newPassword: 'New password',
		repeatNewPassword: 'Repeat new password',
		repeatPassword: 'Repeat password',
		Cancel: 'Cancel',
		Close: 'Close',
		Save: 'Save',
		Saved: 'Saved',
		Saving: 'Saving',
		yourPasswordSuccessfullySaved: 'Your new password is successfully saved',
		serviceWillBeFullyOperational: 'The service will be fully operational until',
		Fonts: 'Fonts',
		mainFont: 'Base font',
		additionalFont: 'Additional font',
		Delete: 'Delete',
		Upload: 'Upload',
		addOneMoreFont: 'Add one more font',
		addOneMoreColor: 'Add one more color',
		uploadYourLogo: 'Upload your logo on a white background in JPG or PNG format',
		uploadImage: 'Upload image',
		Change: 'Change',
		uploadYourMainFont: 'Upload your base font in Bold and Regular type',
		inputYourRGBColor: 'Enter your RGB color or six-digit Hex number',
		Or: 'Or',
		Undo: 'Undo',
		membersCount: 'Amount of members',
		corporateTariff: 'Corporate Tariff',
		findByNameOrEmail: 'Find by username or e-mail',
		addUser: 'Add user',
		Name: 'Name',
		Role: 'Role',
		changeBalance: 'Change balance',
		makeUser: 'Make a user',
		makeAdmin: 'Make an admin',
		employee: 'employee',
		corporateAccountUser: 'corporate account user',
		corporateAccountAdministrator: 'corporate account administrator',
		deleteUser: 'Delete user',
		requestSent: 'Request is sent',
		inviteAgain: 'Invite again',
		cancelInvite: 'Cancel invitation',
		enterEmail: 'Enter e-mail',
		Limit: 'Limit',
		sendToAll: 'Send to all',
		generateLinkFor: 'Generate a link for',
		copyLink: 'Copy link',
		byMail: 'By mail',
		byLink: 'By link',
		errorHasOccurred: 'An error has occurred. Please Try again',
		pleaseHelpUsImproveWonderslide: 'Please help us improve Wonderslide',
		shareYourFeedback: 'Share your feedback',
		youHaveALotToSay: 'You have a lot to say!',

		aboutProject: 'About the project',
		iCanPredict: 'I can tell the future',
		trainedNeuronetEvaluates: 'Trained neuronet evaluates presentation design and gives back nerdy comments',
		grainchildWould: 'Grandson makes it better',
		chooseOrDrag: 'Select or drag-and-drop the file here',
		chooseFile: 'Select{0}file',
		analizingSlide: 'Slide analyzing',
		points: 'points',
		from: 'from',
		startAgain: 'Start again',
		shareResult: 'Share your result',
		automaticDesign: 'Automatic presentation design',
		presentaionAcademy: 'Academy of Bonnie and Slide presentation',
		ourNeuronet: 'Our neuronet is a black box',
		neuronetTaughtOnSlides: 'Wonderslide neuronet is trained on the database of "Bonnie & Slide". It thinks like B&S',
		iAmSubjective: 'I’m so subjective and might be wrong',

		wcheckText1: 'It is not clear what the author wanted to say. He seems to hate people and wish them harm.',
		wcheckText2: 'I saw how such presentations were made on first computers. Do they still exist?',
		wcheckText3: 'The presentation is unpresentable. Unwatchable, unreadable and must be destroyed immediately',
		wcheckText4: 'If this presentation was a bride, then it would be stolen just to not show it to anyone',
		wcheckText5: 'Beep beep. Error. When trying to read the presentation, the C drive is damaged. Formatting is required.',
		wcheckText6: 'So, now slowly lower the mouse and keyboard on the table and raise your hands so that I can see them.',
		wcheckText7: 'Hey man, what did you put in me?! Delete now! I hope you didn’t touch it yourself.',
		wcheckText8: 'Yes, this presentation is terrible. And what are you going to do to me, man?',
		wcheckText9: 'Ktuulkhu fhrtaaarrghghn! Avada Kedavra! Fus ro yes! Understood nothing.',
		wcheckText10: 'Is this a presentation for the CIA? Not? Why then is everything so encrypted?',
		wcheckText11: 'Well, nothing, nothing. But you are beautiful. Or you cook well. Presentations are not for you, but that’s fine.',
		wcheckText12: 'I’ve seen worse things than your presentation. For example, child labor and the death of dinosaurs.',
		wcheckText13: 'An unrepresentative presentation was presented, presented, but not presented.',
		wcheckText14: 'On the street is the 21st century, progress, neural networks, blockchain, smart cities, and you still can’t cope with Power Point.',
		wcheckText15: 'Of course I’m a machine, I can’t create a masterpiece and all that. But even I understand that this is no good.',
		wcheckText16: 'The ancient Greeks made presentations better. And for this they had to hammer stones!',
		wcheckText17: 'It seems that after you completed this presentation, a cat walked over the keyboard. And then lay down. The cat is normal, but the presentation is so-so.',
		wcheckText18: 'Let’s tell the boss that you played squid with fonts. You fought bravely, but you lost.',
		wcheckText19: 'Stick-stick, cucumber - so the little man came out. But the presentation is still mediocre.',
		wcheckText20: 'If the neural network was able to sympathize, I would say that this is not bad. It’s good that I can’t.',
		wcheckText21: 'Impressionism has long been out of fashion. Trying of course nothing, but I would recommend a more classic design.',
		wcheckText22: 'The presentation is like an artist’s room. You know where everything is, but an outsider will hurry to escape from here.',
		wcheckText23: 'If I were an investor, after such a presentation, I would say “OK, we will call you back.”',
		wcheckText24: 'This presentation can only be made better if you attach a monitor to it. And that is inaccurate.',
		wcheckText25: 'If the perfect presentation is a unicorn, then here we have something like a seal.',
		wcheckText26: 'I can predict the future: you will definitely be sent to finish this presentation.',
		wcheckText27: 'I know, I know. Presentations are not yours and generally you were forced. But maybe another approach?',
		wcheckText28: 'You did better than 94% of users. But only with loading. The design is still the same.',
		wcheckText29: 'Heard anything about selling red and premium black? So, forget it.',
		wcheckText30: 'Well, okay, okay. The main thing is that almost everything is clear. If they don’t understand something, they will ask.',
		wcheckText31: 'Nice nice. Here it is a little processed with a file and you can show it to people.',
		wcheckText32: 'I don’t know if the boss will accept her, but he certainly won’t kill her. You can go, your life is not in danger.',
		wcheckText33: 'Not bad. You have reached level 12 and receive an artifact, Presentation Protector’s Helm. +5 to directness.',
		wcheckText34: 'I once saw a presentation that a dolphin made. By the way, their mental abilities are greatly exaggerated. So, this one is better.',
		wcheckText35: 'My chips are satisfied. Even though I don’t actually have chips. Who cares anyway',
		wcheckText36: 'With such a presentation, you can protect both the quarterly report and the plan for the colonization of Mars.',
		wcheckText37: 'This slide would look great somewhere in the Louvre or the Hermitage. I’ll pass it on and maybe they’ll contact you.',
		wcheckText38: 'Once, a designer uploaded a 100-point presentation here, and then moved to Bali and founded a successful startup.',
		wcheckText39: 'Lord Almighty! After this, even the neural network will believe. Will you teach the same?',
		wcheckText40: 'If you only knew how much visual slag I get fed every day. But this is a direct balm for the neural soul. Thanks!',
		wcheckText41: 'The Japanese have a word for it, Kaizen, continuous improvement. I take off my neural hat, master.',
		wcheckText42: 'And who is this good fellow here? Go kiss me!',

		howItWorks: 'How it works',
		Features: 'Features',
		AboutUs: 'About us',
		Create: 'Create',
		uploadDraft: 'Upload draft',
		uploadDraftSlides: 'Upload draft slides',
		Pricing: 'Pricing',
		Corporate: 'Corporate',
		Legal: 'Legal',
		TermsOfUse: 'Terms of Use',
		TermsOfService: 'Terms of Service',
		PrivacyPolicy: 'Privacy Policy',
		CookiePolicy: 'Cookie policy',
		cookiePopupText: 'By staying on our site, you agree to our use of cookies. Read more in the',
		GotIt: 'Got{0}it',
		Support: 'Support',
		followUsText: 'Follow us to learn about cool{0}design tricks for presentations',

		paymentErrorMsg1: 'Something went wrong during payment.',
		paymentErrorMsg2: 'Please repeat or write to our technical support.',
		paymentSuccessMsg: 'You have successfully paid for the plan.{0}Get your wonder slides and our gratitude!',
		paymentCancelMsg1: 'You have canceled a payment.',
		paymentCancelMsg2: 'Please select a plan and try again.',
		accountConfirmedMsg: 'Welcome aboard! Your account is confirmed.{0}Now you can download presentations',
		confirmeAccountMsg: 'Verify your email to get full access to the service.{0}Please follow the link sent to your email.',
		resendEmail: 'Resend email',
		sendValidationLinkAgain: 'Send validation link again.',
		weSentYouValidationLink: 'We sent you the validation link.{0}Please check your email.',
		sessionTimeoutNotify: 'You last used the service a long time ago.{0}To protect your data, we have reset the session.{0}To continue working, upload your file again.',

		Blog: 'Blog',
		MarketingAdvertisingAndPR: 'Marketing,{0} Advertising and PR',
		TechnologyAndDigital: 'Technology{0} and Digital',
		FoodCafesAndRestaurants: 'Food, Cafes{0} and Restaurants',
		TransportAndLogistics: 'Transport{0} and Logistics',
		SportsAndFitness: 'Sports{0} and Fitness',

		ContinueWithGoogle: 'Continue with Google',
		LoginByEmail: 'Log in by E-mail',
		SignupByEmail: 'Sign up by E-mail',
		IForgotMyPassword: 'I forgot my password',
		Password: 'Password',
		Email: 'Email',
		Signin: 'Sign in',
		RegisterAndGetFullAccess: 'Register and get full access for 7 days!',
		ByContinuingYouAgreeTo: 'By continuing, you agree to Wonderslide’s',
		and: 'and',
		IAgreeToReceiveEmails: 'I agree to receive emails about great slide decks, lifehacks & tricks, Wonderslide updates, etc.',
		RestorePassword: 'Restore Password',
		EmailAddres: 'Email address',
		WeSentYouPasswordResetLink: 'We sent you the password reset link. Please check your email.',

		PlanFor: 'Plan for a',
		year: 'year',
		month: 'month',
		Yearly: 'Yearly',
		Monthly: 'Monthly',
		YearlySubscription: 'Yearly Subscription',
		MonthlySubscription: 'Monthly Subscription',
		PayAsYouGo: 'Pay-as-you-go',
		plan: 'plan',
		YearOfBestPresentations: 'A year of best presentations,{0}all features included',
		FullAccessToAllFeatures: 'Full access to all features',
		AreLoading: 'Loading...',
		SelectPlan: 'Select plan',
	}
});

export default t;