import * as Sentry from "@sentry/browser";

export const slidesSentry = {
    addSlide: ({ errors, newTo }: {errors: string, newTo: number}) => {
        Sentry.captureException({
            function: 'addSlide',
            msg: errors,
            index: newTo,
        });
    },
    regenSlide: ({errors, slideIdx}: {errors: string; slideIdx: number}) => {
        Sentry.captureException({
            function: 'regenDraft',
            msg: errors,
            index: slideIdx,
        });
    },
    remakeSlide: ({errors, slideIdx}: {errors: string, slideIdx: number}) => {
        Sentry.captureException({
            function: 'regenDraft',
            msg: errors,
            index: slideIdx,
        });
    }
}