import React from "react";
import PropTypes from "prop-types";

import RegisterQuiz from "./index";
import { registerAnalytics } from "../../../utils/analytics";

import { RegisterTemplate } from "../";

const RegisterQuizFullWidth = (props) => {
    const { userId, method='google', onClose } = props;
    function closeQuizHandler(){
        registerAnalytics.userRegistered({ method, userId });
        onClose?.();
    }
    return (
        <div className="auth_quiz__fullWidth" >
            <RegisterTemplate>
                <div style={{ paddingTop: '17px'}}>
                    <RegisterQuiz close={closeQuizHandler} />
                </div>
            </RegisterTemplate>
        </div>
    );
};

RegisterQuizFullWidth.prototype = {
    /* user ID of auth user for analytics */
    userId: PropTypes.string,
    /* method of registration */
    method: PropTypes.oneOf(['google', 'email']),
    /* event closing of quiz */
    onClose: PropTypes.func,
}

export default RegisterQuizFullWidth;