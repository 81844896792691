const pageRoot = {
    legal: '/legal',
}
export const route_path = {
    main: '/',
    about: '/about/',
    create: '/create/',
    setupStyle: '/create/setup-style/',
    uploadDraft: '/create/upload-draft/',
    features: '/features/',
    pricing: '/pricing/',
    legal: `${pageRoot.legal}/`,
    terms: `/terms/`,
    privacy: '/privacy/',
    cookie: '/cookie/',
    legal_terms: `${pageRoot.legal}/terms/`,
    legal_privacy: `${pageRoot.legal}/privacy/`,
    legal_cookie: `${pageRoot.legal}/cookie/`,
    project: '/project/',
}