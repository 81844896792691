import React, {useState} from "react";
import PropTypes from "prop-types";


const QUIZ_CONTENT = {
    title: 'Finish registration',
    description: `Just one step before registration! \n Please answer to help us get to know you better..`,
    quest: 'What’s your presentation’s purpose?',
    skipBtn: 'Skip question',
    submit: 'Submit and register',
    items: [
        "Teaching and training",
        "HR and People development",
        "Marketing",
        "Sales",
        "Management",
        "I'm a student",
        "Other category"
    ],
    values: [
        'teaching_and_training',
        'hr_and_people_development',
        'marketing',
        'sales',
        "management",
        "im_a_student",
        'other_category',
    ]
}


const RegisterQuiz = (props) => {
    const { close } = props;
    const [activeItem, setActiveItem] = useState(null);
    const [ isFetching, setIsFetching ] = useState(false);

    const submit = () => {
        setIsFetching(true);
        localStorage.setItem('reg_quiz', QUIZ_CONTENT.values[activeItem]);
        const identify = new amplitude.Identify();
        identify.set('user usecase', QUIZ_CONTENT.items[activeItem] + ' [on reg page v2]');
        amplitude.identify(identify);
        close?.();
    }
    function skipQuizHandler (){
        localStorage.setItem('reg_quiz', 'skip');
        const identify = new amplitude.Identify();
        identify.set('user usecase', 'skipped [on reg page v2]');
        amplitude.identify(identify);
        close?.();
    }

    return(
        <div className="auth_quiz__container">
            <div className="auth_title">{QUIZ_CONTENT.title}</div>
            <div className="auth_quiz_text">{QUIZ_CONTENT.description}</div>
            <div className="auth_quiz_quest">{QUIZ_CONTENT.quest}</div>
            <ul className="auth_quiz_items">
                {QUIZ_CONTENT.items.map((item, i) => (
                    <li className={'auth_quiz_item' + (activeItem === i ? ' active' : '')} key={i} onClick={() => {setActiveItem(i)}}>{item}</li>
                ))}
            </ul>
            <div className="auth_quiz_btn__wrapper">
                <div>
                    <div className="auth_quiz_skip_btn" onClick={skipQuizHandler}>{QUIZ_CONTENT.skipBtn}</div>
                </div>
                <div
                    className={'main_btn auth_quiz_btn' + (activeItem === null || isFetching ? ' auth_quiz_btn__disabled' : '')}
                    onClick={!isFetching ? () => submit() : null}>
                        {QUIZ_CONTENT.submit}
                </div>
            </div>
        </div>
    );
}

RegisterQuiz.prototype = {
    /* close or skip quiz */
    close: PropTypes.func,
}
export default RegisterQuiz;