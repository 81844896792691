import {
    fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/react";
import { cookie } from "../../../lib/utils";

const csrftoken = document.cookie.match(/csrftoken=[^;]+/g)?.[0]?.split('=')?.[1] ?? null;

const prepareHeaders = (csrftoken: string | null) => (headers: Headers) => {
    headers.set("X-CSRFToken", csrftoken  ?? String(cookie.get('csrftoken')));
    if (!headers.has("Content-Type")) {
       headers.set("Content-Type", "application/json");
    }else{
        headers.get('Content-Type') === 'multipart/form-data' && headers.delete('Content-type');
    }
    headers.set("X-Requested-With", "XMLHttpRequest");
    return headers;
}
const currentHostUrl = String(window.location.protocol || "http:" + '//' + window.location.host + '/');
export const appBaseQuery = () => fetchBaseQuery({
    baseUrl: currentHostUrl,
    prepareHeaders: prepareHeaders(csrftoken),
    credentials: 'same-origin',
});

export const appBaseQueryWithResend: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =  async (args, api, extraOptions) => {
    let result = await appBaseQuery()(args, api, extraOptions);
    return result;
}