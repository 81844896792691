import React, { FC } from 'react';
import t from "../../../../lib/lng";

type AccountConfirmNotifyProps = {
    close: () => void;
}
const AccountConfirmNotify:FC<AccountConfirmNotifyProps> = (props) => {
    const { close } = props;
    function closeHandler(){
        close?.();
    }
    return (
        <div className="modal">
            <div className="modal_overlay" onClick={closeHandler}></div>
            <div className="modal_notify">
                <div className="modal_close" onClick={closeHandler}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <line y1="-1" x2="21.2624" y2="-1"
                              transform="matrix(0.706015 0.708197 -0.706015 0.708197 1 2.35706)" stroke="#f8f8fb"
                              strokeWidth="2"/>
                        <line y1="-1" x2="21.2624" y2="-1"
                              transform="matrix(-0.706015 0.708197 0.706015 0.708197 16.7895 2.35706)" stroke="#f8f8fb"
                              strokeWidth="2"/>
                    </svg>
                </div>
                <img className="modal_notify_img" src="/static/i/w13.webp" alt=""/>
                <div className="modal_notify_msg">{t.formatString(t.accountConfirmedMsg, <br/>)}</div>
                <div className="about_btn" onClick={closeHandler}>{t.OK}</div>
            </div>
        </div>
    );
};

export default AccountConfirmNotify;